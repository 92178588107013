:root {
  --primary-white-color: #F7F9FB;
  --primary-blue-color: #8FC1E3;
  --secondary-blue-color: #5085A5;
  --third-blue-color: #31708E;
  --primary-slate-color: #557A95;
}

.App {
  text-align: center;
}
