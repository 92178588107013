@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

#runlog {
  background-color: var(--primary-slate-color);  
}

/*RunLog Header Begin*/

#runLogHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;  
  background-color: black;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;  
  border-bottom: 2px solid var(--primary-blue-color);
}

.runLogHeaderBtn {
  margin: 1vh;   
}

#exitIcon {
  margin-right: 1vw;
}

#exitIcon:hover {
  box-shadow: 5px 5px 5px var(--primary-blue-color);
}

/*RunLog Header End*/

/* Runlog table Begin*/

#runLogTableHeader {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 2.5% 18% 14% 18% 18% 16% 2.5%;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--primary-slate-color);
  font-size: small;  
  border-bottom: 1px solid var(--primary-white-color); 
}

#runLogBodyContainer {
  padding-top: 75px;
}

#runLogBody {
  display: grid;
  grid-template-columns: 2.5% 18% 14% 18% 18% 16% 2.5%;
  font-size: small;  
  align-items: center;
  margin-bottom: 1px;
}

#runLogBody:nth-child(odd) {
  background: var(--primary-blue-color);
}

#runLogBody:nth-child(even) {
  background: var(--secondary-blue-color);
}

#runLogBody:hover {
  background: var(--third-blue-color);
}

.expandIcon:hover {
  border-color: var(--primary-white-color);
  border-style: solid;
  border-width: 1px;
}


/*Runlog table End*/


/* Runlog footer Begin */

#runlogFooter {
  position: fixed;
  left: 0;
  bottom:0;   
}

#runlogFooterContainer {
  width: 100vw; 
  min-height: 3vh;
  display: grid; 
  grid-template-columns: 95% 5%;
  justify-items: center;
  align-items: center;
  border-top: 3px solid var(--primary-blue-color);
  background: var(--primary-white-color);
  padding: 2px;
}

#footerIconContainer {
  width: 7vw;
  position: fixed;
  left: 1vw; 
  border-radius: 10%;
  bottom: 1vh;  
  background-color: var(--primary-white-color);  
  border: 3px solid var( --third-blue-color); 
}

#footerIconContainer:hover {
  box-shadow: -5px 5px 5px var(--primary-white-color);
}

#footerIcon {
  width: 90%
}

#footerExitIcon {
  margin-right: 2vh;
}

#footerExitIcon:hover {
  border: 2px solid var(--primary-blue-color);
  box-shadow: -1px 10px 5px var(--secondary-blue-color);
}

/*Runlog footer End*/


/*RunLog Sorting Modal Begin*/

.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.runLogModalBtn {
  margin: 5px;
  flex-grow: (1);
  min-width: 110px;
}

/*RunLog Sorting Modal End*/


/*More Info Component Begin*/

.runLogMoreInfoContentContainer {
  min-height: 100vh;
  display: flex;   
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary-slate-color);
}

.runlogMoreInfoBox {
  background-color: var(--primary-blue-color);
  min-width: 70%;
  max-width: 90%;
  min-height: 80%;
  display: flex; 
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-color: var(--third-blue-color);
  border-style: solid;
  border-width: 5px;
  border-radius: 5%;
  box-shadow: -5px 5px 5px var(--primary-white-color);
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.runlogMoreInfoLabel {
  text-align: left; 
}

#runlogMoreInfoTable {
  font-size: small;
}

.runlogMoreInfoButtonContainer {
  margin: 1vh;   
}

/*More Info Component End*/



/* Begin New Run Component */

#newRunContainer {
  font-family: 'Staatliches', cursive;
}

#newRunAuthExitIconContainer {
  width: 95%;
  display: flex; 
  justify-content: flex-end;
  align-items: flex-start;
}

#newRunAuthExitIcon {
  text-align: end;
  text-decoration: none;
}

/* End New Run Component */


/* Begin New Run Form */

#newRunFormContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.newRunRowLabel {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* End New Run Form */