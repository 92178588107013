@import '../App.css';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital@1&display=swap');

#landingContainer {
    background-color: var(--primary-slate-color);
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100vh; 
}

#landingBox {
    background-color: var(--third-blue-color);
    width: 90%; 
    height: 70%; 
    display: flex; 
    justify-content: center;
    align-items: center;
    border-width: 5px;
    border-style: solid;
    border-color: var(--primary-blue-color);
    border-radius: 5%;
    box-shadow: -5px 5px 5px var(--primary-white-color);
    flex-direction: column;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: x-large;
}

#runnerIcon {
    font-size: 5rem;
}