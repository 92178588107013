@import '../runLog/runLog.css';

#aboutContentContainer {
    width: 90%; 
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

#aboutContentContainer a {
    color: var(--primary-white-color);
    text-decoration: none;
}

.aboutTitleContainer {
    width: 100%;
    margin-top: 2vh;
    margin-left: 10%;
    
}

.aboutTitle {
    box-shadow: 0px 10px 10px var(--primary-white-color);
}

#steveAndDaniaImage {
    height: 15vh;
    max-width: 25vw;
    opacity: .6;
    border: 5px solid var(--primary-white-color);
    border-radius: 5%;
    box-shadow: -5px 10px var(--primary-slate-color);
}

#aboutAticlesContainer{
    display: flex; 
    justify-content: flex-start;
    align-items: space-between;
    flex-direction: column;
    text-align: start;
    padding-left: 2vw;
     
}

#aboutIconContainer {
    width: 100%;
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;          
    margin-left: 10%;
    padding: 5%;    
    color: black;   
}

#aboutIconContainer a {
    color: black;
    font-size: xx-large;
}

#aboutIconContainer a:hover {
    box-shadow: -5px 10px 7px var(--primary-white-color);
    background-color: var(--primary-blue-color);
    padding: 0; 
    margin: 0;
    border: 5px solid var(--primary-blue-color)
}

.aboutContentBio {
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#aboutContentImageContainer {
    display: flex; 
    padding: 5%;
    align-items: center;
    justify-content: center;
}

.aboutContentBioContainer {
    display: flex; 
    margin-right: -10%;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    flex-direction: column;
}

#aboutArchiveTextContainer {
    display: flex; 
    text-align: center;
    width: 100%; 
    margin-left: 5%; 
    justify-content: center;
    padding-top: 1vw;
    font-size: xx-small;
    align-items: center;
    align-content: center;
    margin-top: 2vw;
}
